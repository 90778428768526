.letter-O {
  animation-name: bouncing-O;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes bouncing-O {
  /*squished on ground */
  0% {
    transform: scale(1.1, 0.8) translateX(-5%) translateY(10%);
  }
  /* normal */
  10% {
    transform: scale(1, 1) translateX(0) translateY(0);
  }
  /* in air, skinny */
  20% {
    transform: scale(0.9, 1.1) translateX(6.2%) translateY(-10%);
  }
  /* in air, normal */
  60% {
    transform: scale(1, 1) translateX(0) translateY(-13%);
  }
  75% {
    transform: scale(1, 1) translateX(0) translateY(-10%);
  }
  /* normal */
  85% {
    transform: scale(1, 1) translateX(0) translateY(0%);
  }
  100% {
    transform: scale(1.1, 0.8) translateX(-5%) translateY(10%);
  }
}

.hat {
  animation-name: bouncing-hat;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes bouncing-hat {
  /*squished on ground */
  0% {
    transform: scale(1, 0.8) translateX(0) translateY(10%);
  }
  /* normal */
  10% {
    transform: scale(1, 1) translateX(0) translateY(0);
  }
  /* in air, skinny */
  20% {
    transform: scale(0.9, 1.1) translateX(6.2%) translateY(-10%);
  }
  /* in air, normal */
  65% {
    transform: scale(1, 1) translateX(0) translateY(-16%);
  }
  75% {
    transform: scale(1, 1) translateX(0) translateY(-13%);
  }
  85% {
    transform: scale(1, 1) translateX(0) translateY(-8%);
  }
  100% {
    transform: scale(1, 0.8) translateX(0) translateY(10%);
  }
}
